/* Algemeen */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

:root {
    --maincolor: #FF3D00;
    --sec: #d43200;
    --white: #FFFFFF;
    --black: #000000;
    --opacity:rgba(0, 0, 0, 0.362);

    --transform: none;
    --play: running;
    --direction: normal;
    --duration: 1.5225s;
    --delay: 0s;
    --iteration-count: infinite;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

a{
  text-decoration: none !important;
  color: var(--black) !important;
  font-weight: 700 !important;
}

/* Navbar */
header {
  height: 20vh;
}
nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7vw;
  padding-right: 5vw;
  height: 100%;

}
.logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo h4{
  
  font-family: 'Lilita One', cursive;
  font-size: 2em;
  color: var(--maincolor);
}

main{
  min-height: fit-content;
  overflow-y: hidden;
  overflow-x: hidden;
}


/* ComingSoon */
.coming-soon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}
.coming-soon__title{
  font-family: 'Lilita One', cursive;
  font-size: 5em;
  color: var(--maincolor);
}
.coming-soon__subtitle{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    color: var(--opacity);
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.coming-soon__cone{
  height: 20em;
  position: fixed;
  bottom: 120vh;
  animation: fallCone 2s ease-in-out forwards;
}
.cone_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@keyframes fallCone {
  0% {
    bottom: 120vh;
  }
  10% {
    bottom: 50vh;
  }
  50% {
    bottom: 0vh;
    transform: rotate(90deg);
  }
  100% {
    bottom: 0vh;
    transform: rotate(0deg);
  }

}

.coming-soon__countdown{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
}
.coming-soon__countdown__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 1em;
}
.coming-soon__countdown__item__number{
    font-family: 'Lilita One', cursive;
    font-size: 3em;
    color: var(--maincolor);
}
.coming-soon__countdown__item__text{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 1em;
    margin-bottom: 80px;
}


@media (max-width: 1350px) {
    .coming-soon__cone{
        height: 15em;
    }
    .coming-soon__countdown__item__number{
        font-size: 2em;
    }
    .coming-soon__countdown__item__text{
        font-size: 0.8em;
    }
}

/* NotFound */
.nietgevonden{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 100vw;
}
.nietgevonden div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.btnnietgevonden{
    background-color: var(--maincolor);
    border-radius: 10px;
    color: var(--white) !important;
    padding: 1em 5em;
    margin-top:2vh;
    font-weight: 700;
}
.contactcont{
  /* overflow-y: hidden; */
}

.nietgevonden h1{
  font-family: 'Lilita One', cursive;
  font-size: 5em;
  color: var(--maincolor);
}

.nietgevonden h2{
    font-weight: 400;
    color: var(--opacity);
}


/* Landing */
main{

}

/* Landing1 */
.landing1Section{
  padding : 0 7vw;
  min-height: 80vh;
  height: 80vh;
  display: flex;
  /* border : 1px solid red; */
}
section div{
  /* border : 1px solid blue; */
}
.leftSection{
  width:50%;
 
}
.slogan{
  margin-top: 1em;
  color: var(--black);
  font-size: 2em;
  /* animation: fadeInAnimationRight ease 1s; */
  font-weight: 700;
}
.sloganHit{
  color: var(--maincolor);
  font-size: 5em;
  font-family: 'Lilita One', cursive;
}
.infoLeftSection{
  margin-top: 2em;
  font-size: 1.2em;
  font-weight: 400;
}
.infotext{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.highlight{
  color: var(--maincolor);
  font-weight: 700;
}
.socials{
  display: flex;
  align-items: center;
  margin-top: 2em;
}
.socials a{
  margin-right: 1em;
}
.iconSocial{
  font-size: 2em;
  color: var(--maincolor);
}
.scrolldown{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}
.scrolldown2 a{
  color: white !important;
}
.scrolldown2 .line{
  
  border-right: 3px solid white;
}
.scrolldownText{
  font-size: 1.2em;
  font-weight: 400;
  position: absolute;
  bottom: 14vh;
  left:7vw;
  color: black !important;

}

.line {

  position: absolute;
  bottom: 0;
  left: 9vw;
  border-right: 3px solid black;
  animation: borderupdown 4s infinite;
  animation-fill-mode: forwards;
  width: 25px;
}

@keyframes borderupdown {
  0% {
      bottom: 0;
      height: 0;
  }

  50% {
      height: 100px;
      transform: translate(0, 0);
      opacity: 1;
      bottom: 0;
  }

  80% {
      transform: translate(0, 0px);
      height: 0;
      opacity: .5;
      bottom: 0;
  }

  100% {
      height: 0;
      bottom: 0;
  }
}

.rightSection{
  width:60%;
  height: 100%;
  position: relative;
 
}
.splashwh{
  position: absolute;
  top: -30px;
  
  right: -50px;
}
.landingThing{
  position: absolute;
  top: -20%;
  right: -10%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  z-index: -1;
  padding : 4.5vw;
  transition: 0.5s;
}

.triangles{
  position: absolute;
  top:5rem;
  right: 10rem;
  height: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: -1;
}
.driehoeken{
  position: absolute;
  width: 80%;
  height: 100%;
  margin-left: 10vw;
}
.polygon1{
  width: 100px;
  top: -5vh;
  right: 10vw;
  position: absolute;
  animation: ease rotatein 1s ;
}
.polygon2{
  width: 64px;
  bottom: 30vh;
  left: 12vw;
  position: absolute;
  animation: ease rotatein 1s ;
}
.polygon3{
  bottom: 10vh;
  right: 10vw;
  width: 36px;
  position: absolute;
  animation: ease rotatein 1.5s ;
}
@keyframes rotatein{
  0%{
    opacity: 0;
    transform: translateX(5rem);
    transform: rotate(0deg);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimationRight {
  0% {
      opacity: 0;
      transform: translateX(-4rem);
  }

  100% {
      opacity: 1;
      transform: translateX(0);
  }
}

/* Landing 2 */
.second{
  min-height: 140vh;
  padding : 10vh 7vw;
  flex-direction: column;
  margin : 0;
}

.landing2Section{
 
}
.orangeBack{
  background-color: var(--maincolor);
  min-height: 30vh;
}

.mission{
  width: 100%;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(255,61,0,1) 46%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.missionSmall{
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--opacity);
}

.blokken{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10vh;
}
.blok{
  width: 32%;
  height: fit-content;
  min-height: 40vh;
  background-color: var(--white);
  border : 3px solid var(--maincolor);
  display: flex;
  border-radius: 10px;
  padding : 2em;
  flex-direction: column;
  transition: 0.5s;
}
.modal{

  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--white);
  width: 50vw;
  height: fit-content;
  z-index: 1;
  padding : 5em 2em;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-bottom: 5px solid var(--maincolor);
  border-top: 5px solid var(--maincolor);

}
.titleModal{
  font-size: 2em;
  color: var(--maincolor);
  font-weight: 700;

}
.closeBtn{
 background-color: transparent;
 display: flex;
 align-items: center;
 justify-content: center;
 border: 0;
 cursor: pointer;
}
.closeBtn svg{
  font-size: 2em;
  color: var(--maincolor);
}

.upperModal{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoProjectModal{
  margin-top: 2em;
  display: flex;

}
.leftInfoProjectModal{
  width: 50%;
}
.rightInfoProjectModal{
  width: 50%;
  position: relative;
}
.imgModal{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.subtitle{
  font-size: 1em;
  font-weight: 700;
}

.icon{
  color: var(--white);
  font-size: 2em;
  width: 58px;
  height: 58px !important;
  background-color: var(--maincolor);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  
}
.blok .title{
  font-size: 2em;
  color: var(--maincolor);
  font-weight: 700;
  margin-top: 1em;
  
}
.top{
  margin-top: 15px;
}
.blok hr{
  width: 100%;
  border: 1px solid var(--maincolor);
  margin-top: 1em;
}
.blok .text{
  font-size: 1.2em;
  font-weight: 400;
  margin-top: 1.2em;
  color: var(--black);
}
.blok:hover{
  transform: scale(1);
  cursor: pointer;
  background-color: #FF3D00;
}
.blok:hover .icon{
  background-color: var(--white);
  color: var(--maincolor);
}
.blok:hover .title{
  color: var(--white);
}
.blok:hover .text{
  color: var(--white);
}
.blok:hover hr{
  border: 1px solid var(--white);
}
.checkbtnModal{
  background-color: var(--maincolor);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 20px;
  position: relative;
  width: fit-content;

}

.projects{
 
  display: flex;
  width: 100%;
  height: 50vh;
  border-radius: 10px;

  padding : 3em 7em;
  justify-content: space-between;
  position: relative;
}
.leftProjects{
 
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.titleProjects{
  font-size: 2.5em;
  color: var(--white);
  font-weight: 700;
}
.leftProjects hr{
  width: 100%;
  border: 1px solid var(--white);
  margin-top: 1em;
}
.textProjects{
  font-size: 1em;
  font-weight: 400;
  margin-top: 1.2em;
  color: var(--white);
}
.buttonsProjects{
  display: flex;
  position: absolute;
  bottom: 3em;
}
.bottom{
  margin-bottom: 50px;
}
.viewAllBtn{
  background-color: var(--white);
  border-radius: 5px;
  color: var(--maincolor) !important;
  padding: 1em 3em;
  margin-right: 1em;
  font-weight: 700;
  font-size: 14px;
  border : 3px solid var(--white);
  cursor: pointer;
  transition: 0.5s;
  display: flex;
    justify-content: center;
    align-items: center;
}
.viewAllBtn:hover{
  background-color: #D93400;
  color: var(--white) !important;
}

.galaryBtn{
  background-color: transparent;
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  margin-right: 1em;
  font-weight: 700;
  font-size: 14px;
  border : 3px solid var(--white);
  cursor: pointer;
  transition: 0.5s;
  display: flex;
    justify-content: center;
    align-items: center;
}
.galaryBtn:hover{
  background-color: var(--white);
  color: var(--maincolor) !important;
}


.rightProjects{
  width: 30%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: flex-end;
  align-items: center;
  background-color: #D93400;
  background-image:  url(./img/mockups.webp);
  background-repeat: repeat;
  background-size: cover;
  background-position: center;
  position: relative;

}

.contactcontainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding : 0vw;
  min-height: 50vh;
}
.contactBTNlow{
  background-color: var(--white);
  border-radius: 5px;
  color: var(--maincolor) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 3px solid var(--maincolor);
  cursor: pointer;
  transition: 0.5s;
}
.contactBTNlow:hover, .contactBTN:hover{
  background-color: var(--maincolor);
  color: var(--white) !important;
}
.contactlanding{
  margin-top: 2rem;
}
.contactBTN{
  background-color:var(--white);
  border-radius: 5px;
  color: var(--maincolor) !important;
  padding: 1em 5em;
  border : 3px solid var(--maincolor);
  font-weight: 700;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.ourWork{
  background-color:var(--maincolor);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  border : 3px solid var(--maincolor);
  font-weight: 700;
  margin-right:10px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
}


/* Blog detail */
.headerBlog{
  height: 50vh;
  background-color: var(--maincolor);
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: 15vh;
  color: white;

}
.headerBlog h2  {
  font-weight: 700;
  font-size: 3.5em;
  margin-bottom: 5px;
}
.row{
  display: flex;
  justify-content: space-between;
  margin-top: 2em;
  position: relative;
}
.leftcolumnBlog{
  width: 80%;
  padding : 0 7vw 7vw 7vw;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.blogtags{
  display: flex;
  flex-wrap: wrap;
  margin-top: 2em;
}
.tag{
  background-color: #ff3c003b;
  color: var(--maincolor);
  padding: 0.5em 1em;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: 700;
  transition: 0.5s;
}
.cardBlog{
  margin-top: 2em;
}
.cardBlog h2{
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
}
.cardBlog h5{
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 20px;
}
.cardImg{
 width: 100%;
 min-height: 60vh;
 background: linear-gradient(90deg, transparent, #ebebeb, transparent);
 background-size: 200% 100%;
 animation: shimmer 2s infinite;
 position: relative;
}
.cardImg img{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


li{
  font-weight: bold;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
.cardBlog p{
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}
.articletext{
  margin-top: 10px;
 
}
.oranje{
  color: var(--maincolor);
  text-decoration: underline;
}
.textwrapper{
  margin-top: 40px;
  width: 90%;
}
.textwrapper a{
  color: var(--maincolor);
  text-decoration: underline;
}

.rightcolumnBlog{
  width: 20%;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  padding-right: 3vw;
  top: 0;
  position:absolute;
  right: 3vw;

}
.projectlink{
  padding : 1em ;
  border-bottom : 1px solid var(--maincolor);
  margin-bottom: 10px;

}
.projectlink:hover{
  transition: 0.2s;
  /* transform: scale(1.02); */
  background-color: var(--maincolor);

}
.projectlink:hover{
  
  color: white !important;
}
.cardRightBlog{
  margin-top: 2em;
  display: flex;
  flex-direction: column;


}
.cardRightBlog h2{
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
}
.cardRightBlog h3{
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 10px;
}
.cardRightBlog .imgrightblog{
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  object-fit: cover;
  background-color: #0000002f;
}
.notop{
  margin-top: 0 !important;
}

/* Landing 3 */
.white{
  height: 100vh;
  display: flex;
  
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.leftNav{
  width: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-right: 3px solid var(--maincolor);
  position: relative;
}
.leftNavPos{
  right: 100%;
  position: absolute;
}
.leftNavText{
  position: relative;
  transition: 0.5s;
  border: 3px solid var(--maincolor);
  border-radius: 50%;
  padding: 0.8em;
  margin-top: 1em;
}
.leftNavText:hover{
  background-color: var(--maincolor);
  color: var(--white);
  cursor: pointer;
}

.arrows{
  height: 50vh;
  bottom: 0;
  right: 0;
  position: absolute;
}
.arrow1{
  width: 100px;
  position: absolute;
}
.arrow2{
  width: 64px;
  bottom: 30vh;
  
  position: absolute;
}
.arrow3{
  bottom: 10vh;
  width: 36px;
  position: absolute;
}
.content{
  /* margin-left: 5rem; */
}
.partTitle{
  font-size: 2em;
  font-weight: 700;
}
.partTitleBig{
  color: var(--maincolor);
  font-size: 4em;
  font-family: 'Lilita One', cursive;
}



/* Projects */
.projectsSection{
  flex-direction: column;
  position: relative;
  /* min-height: 130vh; */
  height: fit-content;
}
.infoProjects{
  margin-top: 2rem;
  text-align: center;
  width: 50vw;

}

.infocont{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navProjects{
  display: flex;
  padding: 0.5em 1em;
  border : 3px solid var(--maincolor);
  border-radius: 10px;
  width:  fit-content;
  justify-content: space-between;

}
.navContain{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navItem{
  font-size: 1em;
  font-weight: 700;
  padding: 0.7em 4em;
  color: var(--maincolor);
  transition: 0.5s;
  cursor: pointer;
}
.bord{
  
  border-right: 1px solid var(--maincolor);
}
.activeNavItem{
  color: var(--white);
  background-color: var(--maincolor);
  padding: 0.7em 4em;
  border-radius: 5px;
  transition: 0.5s;
}
.projectsContain{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  min-height: 60Vh;
}

.projectsBlokken{
  margin-top: 5em;
  width: 100%;
  /* display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2em;
 
 
 
  margin-bottom: 2em; */
  padding : 0 7vw;
  margin-bottom: 2em; 


}

.projectWrapper{
  
  height: 30vh;

  width: fit-content;
  margin-bottom: 2em;
  display: flex;
  /* background-color: #F7F7F7; */
  transition: 0.5s;
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.projectWrapper:hover{
  transition: 0.5s;
  transform: scale(1.02);

}
.projectImgwrap{
  
  height: 100%;
  width: 35%;
  object-fit: contain;
  /* background-color: #D9D9D9; */
  /* border-right: 3px solid var(--maincolor); */
  
}

.projectTitle{
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 10px;
}
.projectInfo{
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #F7F7F7;
}
.readmore{
  font-size: 1em;
  color: var(--maincolor);
  margin-top: 10px;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: underline;
}
.projectBlok{
  background-color: #f8f8f8;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 10px;
  height: 40vh;
  width : 100%;
}
.projectBlok:hover {
  transition: 0.5s;
  background-color: #ecebeb;
}
.projectBlok:hover .blokImg{
  transition: 0.5s;
  padding: 0;
  /* opacity: 0.5; */
  filter: brightness(100%);
}
.blokImg{
  padding: 1em;
  height: 70%;
  background-color:  var(--maincolor);
  width: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  transition: 0.5s;
}
.blokInfo{
  padding : 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  height: 30%;
  transition: 0.5s;
}
.blokCat{
  font-size: 1em;
  margin-bottom: 5px;
  font-weight: 400;
  color: var(--maincolor);
}
.blokTitle{
  font-size: 1.2em;
  font-weight: 700;
  color: var(--black);
}
.projectName{
  position: absolute;
  top: 0;
  transition: 0.5s;
  font-weight: 700;
  font-size: 1.2em;
  color: var(--white);
  display: none;
}
.projectBlok:hover .projectName{
  display: block;
  position: absolute;

  top: 0;
  z-index: 1;
  text-align: center;
}
.projectImg{
  border-radius: 10px;
  position: absolute;
  width: 100%;
  object-fit: contain;
  bottom: 0;
  transition: 0.5s;
  height: 100%;
  
  transition: 0.5s;
}
.backpickproject{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--maincolor);
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

/* Contact */
form{
  display: flex;
  flex-direction: column;
  margin-top: 0vh;
  padding : 0 7vw;
  height: fit-content;
  align-items: center;
  margin-top: 5vh
}
.rijForm{
  display: flex; 
  width: 50%;
  justify-content: space-between;
}
.colomForm{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.inputlabel{
  font-weight: 700;
}
.inputField{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.rijFormFull{
  width: 50%;
  padding-left: 20px;
  padding-right: 20px;
}
.inputFieldBig{
  width: 100%;
  height: 15vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding : 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  resize: none;
  transition: 0.5s;
  outline: none;
}
.inputFieldcat{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.btnadd{
  background-color: var(--maincolor);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 20px;
  
}
.inputFieldBig:focus, .inputField:focus{
  
  border : 1px solid var(--maincolor);
}
form{
  height: fit-content;
  margin-bottom: 2em;
}
.green{
  margin-top: 5px;
  color: green;
}
.red{
  margin-top: 5px;
  color: red;
}

/* Footer */
footer{

  width: 100%;
  height: 5vh;
  color: white;
  display: flex;
  padding : 0 7vw;
  align-items: center;
  justify-content: space-between;
  background-color: var(--maincolor);
}
.botfooter{
  position: absolute;
  bottom: 0;
}
.iconfooter{
  font-size: 1.2em;
  color: var(--white);
}
.footericons{
  display: flex;
  align-items: center;
  width: fit-content;
}
.footericons a{
  margin-right: 1em;
}
.footericons div{
  margin-right: 1em;
}
.footerText{
  display: flex;
  align-items: center;

}
.footerText span{
  color: var(--white);
}
.footerText svg{
  margin-right: 0.5em;
  font-size: 1.2em;
  color:  var(--white);
}
.phoneButtons{
  display: none;
}

.gallerySection{
  height: 50vh;
  background-color: var(--maincolor);
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding-top: 15vh;
  color: white;
}
.gallerySection h2{
  font-weight: 700;
  font-size: 3.5em;
}
.orange{
  background-color: var(--maincolor);
}
.orange nav a h4{
  color: white;
}
.theGal{
  padding-top: 5vh;
  display: flex;
  min-height: fit-content !important;
  height: fit-content !important;
  padding-bottom: 5vh;
  flex-direction: column;
}
.galleryRow{
  display: flex;
  min-height: 40vh;
  width: 100%;
}
.scroller{
  
}
.galleryRow div{
  margin: 5px;

}
.galleryBlockSM{
  width: 20%;
  background-color: rgb(223, 223, 223);
}

.galleryBlockBG{
  width: 60%;
  background-color: rgb(223, 223, 223);
}

/* tablet */
@media screen and (min-width:767px) and (max-width:1023px) {
    
  
}

@media screen and (min-width:767px) and (max-width:1200px){
  .contactlanding{
    display: flex;
    flex-direction: column;
  }
  .ourWork{
    margin-right: 0;
    margin-bottom: 10px;
    width: 30vw;
  }
  .contactBTN{
    width: 30vw;
  }
  .rightcolumnBlog{
    display: none;
  }
  .leftcolumnBlog{
    width: 100%;
  }
  .textwrapper{
    width: 100% !important; 
  }
  .scrolldown{
    display: none;
  }
}

/* phone */
@media screen and (max-width:767px) {
  .phoneButtons{
    display: flex;
    flex-direction: column;
  }
  .rightcolumnBlog{
    display: none;
  }
  .leftcolumnBlog{
    width: 100%;
  }
  .textwrapper{
    width: 100% !important; 
  }
/* 
  .infoLeftSection{
    display: none;
  }
  .socials{
    display: none;
  } */
  .galaryBtn{
    margin-right: 0;
  }
  .contactBTNPhone{
    background-color: var(--white);
    border-radius: 5px;
    color: var(--maincolor) !important;
    font-weight: 700;
    border : 3px solid var(--maincolor);
    cursor: pointer;
    transition: 0.5s;
   
    width: 100%;
    height: 3rem;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactBTNPhone:hover{
    background-color: var(--maincolor);
    color: var(--white) !important;
  }
  .ourWorkBTNPhone{
    background-color: var(--maincolor);
    border-radius: 5px;
    color: var(--white) !important;
    font-weight: 700;
    border : 3px solid var(--maincolor);
    cursor: pointer;
    font-size: 16px;
    transition: 0.5s;
    margin-top: 1em;
    width: 100%;
    height: 3rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .scrollBTNPhone{
    display: none;
  }
  .scrollBTNPhone:hover{
    background-color: var(--white);
    color: var(--maincolor) !important;
  }
  .mission{
    font-size: 1.5em;
  }
  .big{
    font-size: 2em;
  }
  .blokken {
    flex-direction: column;
    margin-top: 2em;
  }
  .blok{
    width: 100%;
    height: fit-content;
    margin-bottom: 2em;
    border-bottom: 3px solid var(--maincolor);
    border-radius: 5px;
  }
  .second{
height: fit-content;
  }
  .projects {
    margin-top: 0px;
    flex-direction: column;
    padding : 2em;
    height: fit-content;
  }

  .projectWrapper{
    width: 100%;
    height: fit-content;
    margin-bottom: 2em;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 0px;

  }
  .headerBlog h2{
    font-size: 2.5em;
  }
  .headerBlog div{
   width: 80%;
   text-align: center;
  }
  .cardImg{
    width: 100%;
    height: fit-content;
    min-height: 20vh;
  }
  .projectInfo{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-top: 2em;
    background-color: transparent;
    padding-bottom: 2em;
  }
  .projectImgwrap{
    width: 100%;
    height: 30vh;
    border-right: 0;
    background-color: transparent;
    border-bottom: 3px solid var(--maincolor);
  }
  .modal{
    width: 100%;
    height: fit-content;
    max-height: 100vh;
    padding : 2em;
  }
  .infoProjectModal{
    flex-direction: column;
  }
  .leftInfoProjectModal, .rightInfoProjectModal{
    width: 100%;
  }
  .rightInfoProjectModal{
    margin-top: 2em;
  }
  .btnInfoModal{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .checkbtnModal {
    width: 100%;
    text-align: center;
  }
  .subtitle {
    width: 100%;
    position: relative;
  }
  .leftProjects{
    width: 100%;
    height: fit-content;
    margin-bottom: 2em;
  }
  .rightProjects{
    width: 100%;
    height: 20vh;
    margin-bottom: 0em;
  }

  
  .buttonsProjects{
    position: relative;
    bottom: 0;
    margin-top: 0;
    flex-direction: column;
  }
  .viewAllBtn{
    margin-bottom: 1em;
    margin-top: 1em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contactcontainer{
    
  }
  .contactcontainer a{
    width: 100%;
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
  }
  .landing1{
    height: fit-content;
    position: relative;
  }
  .contactBTNlow{
    width: 100%;
    text-align: center;
  }
  footer{
    flex-direction: column;
    height: fit-content;
    z-index: 1;
  }
  .footericons{
    margin-bottom: 1em;
  }
  .footerText{
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .rijForm{
    flex-direction: column;
    width: 100%;
  }
  .colomForm{
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .rijFormFull{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .inputField{
    padding : 2em 1em;
  }
  .contactcont main{
    padding : 0;
    height: fit-content;

  }
  .botfooter{
    position: relative;
    bottom: 0;
  }
  .btnadd{
    width: 100%;
    text-align: center;
    padding : 2em 1em;
  }

 
  .nr1{
    /* display: none; */
  }
  section{
    flex-direction: column;
  }
  .leftSection{
    width: 100%;
    margin-bottom: 0em;
    
  }
  .slogan{
    margin-top: 0rem;
  }
  .scrolldown{
    position: absolute;
    left: 0;
    padding-right: 2em;
    padding-left: 2em;
    bottom: 1em;
    width: 100%;
  }
  .landing1Section{
    min-height: 100vh;
    height: fit-content;
  }


  .scrolldownText{
    display: none;
    bottom: 0rem;
    border: 0px solid var(--white);
    left: 0;
    font-size: 1em;
    position: relative;
    padding : 1rem;
    background-color: var(--maincolor);
    color: var(--white) !important;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
  .rightSection{
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .landingThing{
    position: relative;
    top: 0;
    right: 0;
    display: flex;
    
    align-items: center;
    justify-content: center;
  }
  .line{
    display: none;
  }
  .contactBTN{
    width: 100%;
    
    
  }
  .contactlanding{
    display: none;
    position: absolute;
    left: 0;
    padding-right: 2em;
    padding-left: 2em;
    bottom: -3em;
    width: 100%;
    
  }
  .driehoeken{
    display: none;
  }
  .polygon1{
    width: 50px;
    top: -5vh;
    right: 10vw;
    position: absolute;
    animation: ease rotatein 1s ;
  }
  .polygon2{
    width: 32px;
    bottom: 30vh;
    left: 12vw;
    position: absolute;
    animation: ease rotatein 1s ;
  }
  .polygon3{
    bottom: 10vh;
    right: 10vw;
    width: 18px;
    position: absolute;
    animation: ease rotatein 1.5s ;
  }
  .inputFieldcat{
    height: 7vh;
  }
  .projectsBlokken {
    grid-template-columns: repeat(1, 1fr);
    padding : 0 2vw;
  }
  .navContain{
    width: 100%;
    display: flex;

    
  }
  .galleryBlockSM{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--opacity);
    font-weight: 700;
    font-size: 0.6em;
  }
  
  .infocont{
    width: 100%;
   
  }
  .infoProjects{
    width: 100%;
    margin-top: 2em;
  }
  .navProjects{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;
    padding: 0;
    margin-bottom: 0em;
    border: 0;
  }
  .bord{
    border: 0;
  }
  .navItem{
    padding: 2em 2em;
    text-align: center;
    background-color: #f8f8f8;
  }
  .activeNavItem{
    background-color: var(--maincolor);
    color: var(--white);
  }
  .projectsSection{
    min-height: fit-content;
    padding : 0 7vw;
  }
.none{
  display: none;
}
}

@media only screen and (max-height: 792px) and (orientation: landscape) {
    .scrolldown{
        display: none;
    }
    .projects {
        min-height: fit-content;
        /* height: 30vh; */
    }
    .contactcontainer{
        height: 15vh;
    }
 
        
}

/* landscape */
@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .coming-soon__cone{
    height: 8em;
  }
  section{
    height: 180vh;
  }
  .smnone{
    display: none;
  }
  .scrolldownText{
    bottom: -80vh;
    border: 3px solid var(--white);
    left: 3.5rem;
    font-size: 1em;
    position: absolute;
    padding : 1rem;
    background-color: var(--maincolor);
    color: var(--white) !important;
    border-radius: 5px;
    width: 15rem;
    text-align: center;
  }
  .line{
    display: none;
  }
  .driehoeken{
    height: 100vh;
  }
  .second{
    min-height: 400vh;
  }
  .blokken {
    flex-direction: column;
  }
  .blok{
    width: 100%;
    margin-bottom: 2em;
    height: fit-content;
  }
  .projects {
    margin-top: 0px;
    
    padding : 2em;
    height: 100vh;
  }
  footer{
    
    height: 10vh;
  }

  form{
    min-height: fit-content;
  }
 
  .rijForm{
    flex-direction: column;
    width: 100%;
  }
  .colomForm{
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .inputFieldcat{
    height: 15vh;
  }
  .inputField{
    padding : 2em 1em;
  }
  .rijFormFull{
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .inputFieldBig{
    padding : 1em 1em;
    height:  30vh;
  }
  .btnadd{
    width: 100%;
    text-align: center;
    padding : 2em 1em;
  }
  .botfooter{
    position: relative;
  }
}



/* tijdelijk */
.galleryBlockSM{
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--opacity);
  font-weight: 700;
}
.galleryBlockBG{
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--opacity);
  font-weight: 700;

}
.lds-ring {

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--thirdcolor) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

